export default {
    data() {
        return {
            breadcrumbsReady: false,
        };
    },
    computed: {
        breadcrumbs() {
            const fallbackBreadcrumb = this.fallbackBreadcrumb || {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
    },
};
